<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
  <div class="form-group mb-0 break-spaces">
      <TextArea
        id="campoConfrontacao"
        :value="form.fields.campoConfrontacao"
        @blur="updateMedicalRecord(form)"
        @inputTextArea="el => updateForm('campoConfrontacao', el)"
        :readonly="!canEdit"
        rows="1"
        placeholder="Descrever"
      />
    </div>
  </FormWrapper>
</template>
  
  <script>
  import { mapState, mapActions } from 'vuex'
  import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

  export default {
    components: {
      FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
      TextArea: () => import('@/components/General/TextArea'),
    },
    computed: {
      ...mapState({
        canEdit: state => state.attendance.canEdit,
        form: state => state.attendance.form.campoConfrontacao,
        attendance: state => state.attendance.attendance,
      }),
    },
    mixins: [attendanceDataMixin('medicalRecordsStore')],
    data() {
      return {
        loading: false,
        ishihara: null
      }
    },
    methods: {
      ...mapActions('attendance/form', ['handleActiveModule', 'updateMedicalRecord', 'destroyMedicalRecord']),
      ...mapActions('attendance/form/campoConfrontacao', ['handleFields', 'handleProps']),
      updateForm(key, value) {
        this.handleFields({ key, value })
      },
      hideForm(){
        this.handleProps({ key: 'showing', value: false })
        this.destroyMedicalRecord(this.form)
        Object.keys(this.form.fields).forEach(key => {
          this.form.fields[key] = null
        });
      },
    },
  }
</script>
<style lang="scss" scoped>

.subtitle {
    color: var(--type-active, #525C7A);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    margin-top: 0.6rem;
}
</style>